
export default {
  data() {
    return {
        regcode: (this as any).$route.params.id,
        Info: "",
        SN: "",
        Lic: "",
      }
  },
  //fetch 原生js 是http数据请求的一种方式
  //fetch 返回promise对象
  methods: {
    messageSendlisten(event) {
      if (event.keyCode == 13) {
        event.preventDefault(); // 阻止浏览器默认换行操作
        return false;
      }
    },
    clear() {
      (this as any).Info = "";
      (this as any).SN = "";
      (this as any).Lic = "";
      (this as any).regcode = (this as any).$route.params.id;
    },    
    async getLic() {
       if((this as any).regcode == undefined){
          var id = (this as any).$route.params.id;
       } else {
          id = (this as any).regcode;
       }
      fetch("api/licsrv/", {
        method: "post",
        body: JSON.stringify({
          RegCode: id ,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          if(res.SN == ""){
            (this as any).Info = "Warning:";
            (this as any).SN = "Wrong Registration Code";
            (this as any).Lic = "Wrong Registration Code";
           } else if (res.SN == "WRONG SN") {
            (this as any).Info = "Warning:";
            (this as any).SN = "Wrong SN Code";
            (this as any).Lic = "Wrong SN Code";
           } else if (res.SN == "SN USED") {
            (this as any).Info = "Warning:";
            (this as any).SN = "This SN Code is USESD";
            (this as any).Lic = "This SN Code is USESD";
           }else {
            (this as any).Info = "License:";
            (this as any).SN = "SN: " + res.SN;
            (this as any).Lic = res.Lic;
          }
        });
    },
    activated () {
        this.getLic();
    },
  },
};
