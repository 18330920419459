<template>
  <div class="home">
    <div class="title">Welcome to SylixOS VSOA license Server</div>
    <br>
    <br>
    <img class="box" alt="Vue logo" src="../assets/vsoa.png">
    <div>
      <br>
      <br>
      <router-link to="/getlicense" class="btn">Get VSOA SylixOS license</router-link>
    <router-view/>
    </div>
  </div>
</template>

<style scoped lang="scss">
.title{
    margin-top:0px;
    font-size:50px;
    color: #081633;
}

.box{
    width:1000px;
    box-shadow: 0 12px 16px 0  rgba(0,0,0,0.24), 0 17px 50px 0 #4e5c65;
}

.btn{
    width:600px;
    background-color: #3c66c0e3; 
    border: none;
    color: #FAFAFA;
    padding: 5px 22px;
    text-align: center;
    text-decoration: none;
    font-size: 45px;
    border-radius: 20px;
    outline:none;
}
.btn:hover{
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
    cursor: pointer;
     background-color: #194699;
      transition: all 0.2s ease-in;
}

</style>

